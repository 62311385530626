.tbf-form {
  &__input-group {
    margin: 5em auto;
  }

  &__button-group {
    margin: 1em auto;

    text-align: right;
  }
}

@primary-color: #155B90;@error-color: #F81D22;@success-color: #52C41A;@warning-color: #FAAD14;@disabled-color: rgba(0, 0, 0, 0.25);@link-color: #155B90;@text-color: rgba(0, 0, 0, 0.65);@heading-color: rgba(0, 0, 0, 0.85);@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);@text-color-secondary: rgba(0, 0, 0, 0.45);@input-label-color: rgba(0,0,0,0.54);@input-text-color: rgba(0,0,0,0.87);@app-header-height: 2.5rem;@border-radius-base: 4px;@font-size-base: 14px;